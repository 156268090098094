import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-apollo'
import { useHistory } from 'react-router-dom'

import { Loader } from '@acorns/web-components'
import { useAnalyticsClient } from '@acorns/web-utils'
import { FormikErrors, useFormik } from 'formikV2'
import * as Yup from 'yup'

import acornsTitle from 'assets/images/acorns-title.svg'
import {
  PasswordResetErrors,
  ResetPasswordWithTokenMutation,
} from 'generated/graphql'
import { routes } from 'src/routes'
import {
  resetPasswordButtonClicked,
  resetPasswordFormErrors,
  resetPasswordFormScreenView,
  resetPasswordSuccess,
  resetPasswordUserCredentialsNotFound,
} from 'utils/segment'
import {
  RESET_PASSWORD,
  RESET_PASSWORD_BUTTON,
  RESET_PASSWORD_LOADER,
  RESET_PASSWORD_SSN_FIELD,
} from 'utils/testing/testIds'

import Layout from '../../components/Layout'
import { InvalidResetToken } from './invalidResetToken'
import resetPasswordWithTokenMutation from './queries/reset-password-with-token-mutation.gql'
import {
  BodyText,
  CenterContainer,
  ChangePasswordButton,
  FormContainer,
  LoaderContainer,
  PasswordFieldContainer,
  SsnField,
  StyledPasswordInputV4,
  StyledTitle,
} from './styled-components'

type FormValues = {
  ssn: string
  password: string
  confirmPassword: string
}

type Props = {
  showSsnField: boolean
  resetToken: string
}

export const ResetPasswordForm = ({ showSsnField, resetToken }: Props) => {
  const history = useHistory()
  const analytics = useAnalyticsClient()
  const [ssnError, setSsnError] = useState('')
  const [samePasswordError, setSamePasswordError] = useState('')
  const [userCredentialsNotFound, setUserCredentialsNotFound] = useState(false)
  const [resetPasswordWithToken, { loading }] =
    useMutation<ResetPasswordWithTokenMutation>(resetPasswordWithTokenMutation)

  const {
    handleSubmit,
    handleChange,
    values,
    isSubmitting,
    errors: formikErrors,
  } = useFormik({
    initialValues: {
      ssn: '',
      password: '',
      confirmPassword: '',
      showSsnField,
    },

    validationSchema: Yup.object().shape({
      showSsnField: Yup.boolean(),
      ssn: Yup.string()
        .matches(/^\d+$/, 'These values should be valid numbers')
        .min(4, 'This value must be four numbers')
        .when('showSsnField', {
          is: true,
          then: Yup.string().required(),
        }),
      password: Yup.string()
        .required()
        .min(8, 'min')
        .max(32, 'max')
        .matches(/\W/, 'one_special_char')
        .matches(/(?=.*[a-z])(?=.*[A-Z])/, 'one_uppercase_and_lowercase_char')
        .matches(/\d/, 'one_number'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'passwords_match')
        .required(),
    }),

    onSubmit: (__, { setSubmitting }) => {
      analytics.track('Button Clicked', resetPasswordButtonClicked())
      resetPasswordWithToken({
        variables: {
          password: values.password,
          passwordConfirmation: values.confirmPassword,
          resetToken,
          last4Ssn: values.ssn,
        },
      }).then(({ data }) => {
        if (
          data.resetPasswordWithToken.__typename ===
            'InvalidPasswordResetException' &&
          data.resetPasswordWithToken.errors
        ) {
          analytics.track(
            'Reset Password Errors',
            resetPasswordFormErrors(data.resetPasswordWithToken.errors),
          )
          setSubmitting(false)
          const resetPasswordErrors = data.resetPasswordWithToken.errors
          if (
            resetPasswordErrors.includes(
              PasswordResetErrors.NewPasswordMatchesOldPassword,
            )
          ) {
            setSamePasswordError('New password matches old password')
          }
          if (resetPasswordErrors.includes(PasswordResetErrors.InvalidSsn)) {
            setSsnError('Invalid SSN')
          }
          return
        }
        if (
          data.resetPasswordWithToken.__typename ===
          'UserCredentialsNotFoundException'
        ) {
          analytics.track(
            'Screen Viewed',
            resetPasswordUserCredentialsNotFound(),
          )
          setSubmitting(false)
          setUserCredentialsNotFound(true)
          return
        }

        if (
          data.resetPasswordWithToken.__typename ===
          'ResetPasswordWithTokenSuccess'
        ) {
          analytics.track('Reset Password Success', resetPasswordSuccess())
        }
        setSubmitting(true)
      })
    },
  })

  const isDisabled = (
    formValues: FormValues,
    formErrors: FormikErrors<FormValues>,
    ssnApiError: string,
    samePasswordApiError: string,
  ) => {
    const isEmpty = () => {
      if (formValues.ssn) {
        if (
          formValues.ssn === '' &&
          formValues.confirmPassword === '' &&
          formValues.password === ''
        ) {
          return true
        }
      } else if (
        formValues.confirmPassword === '' &&
        formValues.password === ''
      ) {
        return true
      }

      return false
    }
    const errorsPresent = Object.values(formErrors).length > 0

    if (isEmpty() || errorsPresent) {
      return true
    }

    if (ssnApiError || samePasswordApiError !== '') {
      return true
    }
    return false
  }

  const onAnimationEnd = () => {
    history.push(routes.resetPassword.success)
  }

  useEffect(() => {
    analytics.track('Screen Viewed', resetPasswordFormScreenView())
  }, [analytics])

  useEffect(() => {
    setSsnError('')
  }, [values.ssn])

  useEffect(() => {
    setSamePasswordError('')
  }, [values.password, values.confirmPassword])

  if (isSubmitting) {
    return (
      <Layout title={<img src={acornsTitle} width={97} height={26} />}>
        <CenterContainer>
          <LoaderContainer data-testid={RESET_PASSWORD_LOADER}>
            <Loader
              loading={loading}
              onAnimationEnd={onAnimationEnd}
              minimumAnimationTime={1000}
            />
          </LoaderContainer>
        </CenterContainer>
      </Layout>
    )
  }

  if (userCredentialsNotFound) {
    return <InvalidResetToken />
  }

  return (
    <Layout title={<img src={acornsTitle} width={97} height={26} />}>
      <CenterContainer>
        <FormContainer>
          <StyledTitle>Reset your Acorns Password</StyledTitle>
          <BodyText>
            Password must contain at least 8 characters, one uppercase letter,
            and one lowercase letter.
          </BodyText>
          <form onSubmit={handleSubmit} data-testid={RESET_PASSWORD}>
            {showSsnField && (
              <div data-testid={RESET_PASSWORD_SSN_FIELD}>
                <SsnField
                  label={{ value: 'Last 4 of SSN' }}
                  inputProps={{
                    name: 'ssn',
                    value: values.ssn,
                    onChange: handleChange,
                    placeholder: 'Enter Last 4 of SSN',
                    type: 'password',
                    maxLength: 4,
                  }}
                  errorText={
                    (values.ssn.length > 0 && formikErrors.ssn) || ssnError
                  }
                />
              </div>
            )}

            <PasswordFieldContainer>
              <StyledPasswordInputV4
                errors={!!samePasswordError}
                label="New Password"
                name="password"
                required={true}
                onChange={handleChange}
                value={values.password}
                placeholder="Enter New Password"
                showPasswordConfirmationField={true}
                passwordConfirmation={{
                  onChange: handleChange,
                  value: values.confirmPassword,
                  name: 'confirmPassword',
                  label: 'Confirm Password',
                  errorText: samePasswordError,
                }}
              />
            </PasswordFieldContainer>

            <ChangePasswordButton
              buttonType={'submit'}
              onPress={handleSubmit}
              data-testid={RESET_PASSWORD_BUTTON}
              disabled={isDisabled(
                values,
                formikErrors,
                ssnError,
                samePasswordError,
              )}
            >
              Change Password
            </ChangePasswordButton>
          </form>
        </FormContainer>
      </CenterContainer>
    </Layout>
  )
}
