import React, { FormEvent, useEffect, useState } from 'react'
import { graphql, withApollo } from 'react-apollo'
import { Redirect, useHistory, withRouter } from 'react-router-dom'

import { AcornsIcon } from '@acorns/icons'
import {
  CheckboxInput,
  ConfirmationModal,
  ModalActionTypes,
  Spinner,
  VerificationInput,
} from '@acorns/web-components'
import { Heading2, Loader } from '@acorns/web-components'
import { useFeature } from '@acorns/web-toggles-react'
import { withAnalyticsClient } from '@acorns/web-utils'
import { Form, FormikActions, withFormik } from 'formik'
import { ExecutionResult } from 'graphql'
import { compose, withHandlers, withProps, withState } from 'recompose'
import styled from 'styled-components'

import { AuthType, AuthenticateMutation } from 'generated/graphql'
import { routes } from 'src/routes'
import { withEnv } from 'utils/environment'
import { trustThisDeviceWeb } from 'utils/experiments/features'
import { hasUdid } from 'utils/udid-utils'
import withAnalyticsIdentity from 'utils/with-analytics-identity'

import Layout from '../../components/Layout'
import {
  ExpiredCodeModalViewed,
  HelpButtonTapped,
  HelpModalViewed,
  LoginRedirect,
  ResendButtonTapped,
} from '../../utils/segment'
import {
  BackButtonContainer,
  BackButtonStyled,
  HeaderSubcopy,
  HeaderSubcopyBolded,
  ResendButton,
  SubmitButton,
  SwitchModeButton,
  SwitchModeContainer,
  SwitchModeIcon,
  TrustThisDeviceContainer,
} from './components'
import AuthenticateExceptionHandler from './exceptions'
import {
  Values,
  handleFormSubmit,
  mapPropsToValues,
  resolveRedirect,
  validationSchema,
} from './form'
import ChallengeAuthenticate from './queries/challenge-authenticate.gql'
import ReinitiateChallenge from './queries/resend-challenge-code.gql'

export type Props = {
  values: Values
  history: History
  isSubmitting: boolean
  isValid: boolean
  isInvalidCodeModalOpen: boolean
  isHelpModalOpen: boolean
  isHavingTroubleModalOpen: boolean
  isExpiredModalOpen: boolean
  phoneNumber: string
  location: any
  resendChallengeId: string
  analytics: any
  resendClickCounter: number
  isSwitchingAuthenticator: boolean
  isResendingChallenge: boolean
  trustThisDeviceWebIsEnabled: boolean
  isLoading: boolean
  title: string
  exception: string
  setException: (value: string) => void
  isExceptionModalOpen: boolean
  setIsExceptionModalOpen: (value: boolean) => void
  setResendClickCounter: (resendClickCounter: number) => void
  setIsInvalidCodeModalOpen: (value: boolean) => void
  setResendchallengeId: (value: string) => void
  setIsHelpModalOpen: (value: boolean) => void
  setIsHavingTroubleModalOpen: (value: boolean) => void
  setIsExpiredModalOpen: (value: boolean) => void
  setIsSwitchingAuthenticator: (value: boolean) => void
  setIsLoading: (value: boolean) => void
  setTitle: (value: string) => void
  onBackPress: () => void
  closeModal: () => void
  handleHelpModal: () => void
  handleResendChallengeCode: (challengeId: string) => void
  handleChange: (event: FormEvent<HTMLInputElement>) => void
  handleSubmit: () => void
  handleSwitchingAuthenticator: (
    values: any,
    alternateAuthenticatorId: string,
    history: History,
  ) => void
  handleBlur: (event: FormEvent<HTMLInputElement>) => void
  reset: (options?: any) => any
  handleCloseExpiredModal: () => void
  env: any
  isOAuth?: boolean
} & FormikActions<{ challengeAnswer: string }>

const BUTTON_TAPPED = 'Button Tapped'
const MAX_LENGTH = 6

export const enhance = compose<any, any>(
  withAnalyticsClient,
  withAnalyticsIdentity,
  withRouter,
  withApollo,
  withEnv,
  graphql(ChallengeAuthenticate, { name: 'login' }),
  withState('isInvalidCodeModalOpen', 'setIsInvalidCodeModalOpen', false),
  withState('isHelpModalOpen', 'setIsHelpModalOpen', false),
  withState('isHavingTroubleModalOpen', 'setIsHavingTroubleModalOpen', false),
  withState('isExpiredModalOpen', 'setIsExpiredModalOpen', false),
  withState('resendChallengeId', 'setResendChallengeId', ''),
  withState('resendClickCounter', 'setResendClickCounter', 0),
  withState('isSwitchingAuthenticator', 'setIsSwitchingAuthenticator', false),
  withState('isResendingChallenge', 'setIsResendingChallenge', false),
  withState('isLoading', 'setIsLoading', false),
  withState('title', 'setTitle', false),
  withState('exception', 'setException', ''),
  withState('isExceptionModalOpen', 'setIsExceptionModalOpen', false),
  withProps(() => {
    const [trustThisDeviceWebIsEnabled] = useFeature(trustThisDeviceWeb)
    return { trustThisDeviceWebIsEnabled }
  }),
  withFormik({
    validationSchema,
    mapPropsToValues,
    handleSubmit: handleFormSubmit,
  }),
  withHandlers({
    onBackPress:
      ({ history, isOAuth }) =>
      () => {
        const url = document.referrer.split('/')
        if (url[3] === 'redeem') {
          history.push('/redeem')
        } else {
          history.push(
            isOAuth
              ? '/oauth' + history.location.data.preservedQueryParams
              : '/',
          )
        }
      },
    handleResendChallengeCode:
      ({
        analytics,
        client,
        resendClickCounter,
        setResendChallengeId,
        setIsExpiredModalOpen,
        setIsResendingChallenge,
        setResendClickCounter,
        setIsHavingTroubleModalOpen,
      }) =>
      (challengeId: string) => {
        setIsResendingChallenge(true)
        analytics.track(BUTTON_TAPPED, ResendButtonTapped('Resend'))
        setResendClickCounter(resendClickCounter + 1)
        if (resendClickCounter === 2) {
          setIsHavingTroubleModalOpen(true)
          setResendClickCounter(0)
        } else {
          return client
            .mutate({
              mutation: ReinitiateChallenge,
              variables: {
                input: { challengeId },
              },
            })
            .then((result) => {
              if (
                result.data.reinitiateChallenge.__typename ===
                'AuthChallengeExpiredException'
              ) {
                setIsExpiredModalOpen(true)
                analytics.track('Container Viewed', ExpiredCodeModalViewed)
              } else {
                setResendChallengeId(result.data.reinitiateChallenge.id)
              }
            })
            .finally(() => {
              setIsResendingChallenge(false)
            })
        }
      },

    handleSwitchingAuthenticator:
      ({
        analytics,
        client,
        setIsExpiredModalOpen,
        setIsSwitchingAuthenticator,
        isOAuth,
      }) =>
      (values: any, alternateAuthenticatorId: string, history) => {
        setIsSwitchingAuthenticator(true)
        analytics.track(
          BUTTON_TAPPED,
          ResendButtonTapped('Switch Authenticators'),
        )
        client
          .mutate({
            mutation: ChallengeAuthenticate,
            variables: {
              input: {
                ...values,
              },
              alternateAuthenticatorId,
            },
            oauth: isOAuth ?? false,
          })
          .then((result: ExecutionResult<AuthenticateMutation>) => {
            if (result.data.authenticate.__typename === 'SMSAuthChallenge') {
              const mode = AuthType.Phone
              const challengeId = result.data.authenticate.id
              const phoneNumber =
                '(•••) ••• ' + result.data.authenticate.maskedPhoneNumber
              const altAuthenticatorId =
                result.data.authenticate.alternateAuthenticators.find(
                  (authenticator) => authenticator.type !== mode,
                )?.id

              history.push({
                pathname: isOAuth ? '/oauth/mfa-challenge' : '/mfa-challenge',
                data: {
                  values,
                  challengeId,
                  phoneNumber,
                  mode,
                  alternateAuthenticatorId: altAuthenticatorId,
                  preservedRedirect: history.location.data.preservedRedirect,
                  preservedQueryParams:
                    history.location.data.preservedQueryParams,
                  oAuthPartnerParams: history.location.data.oAuthPartnerParams,
                },
              })
            } else if (
              result.data.authenticate.__typename === 'EmailAuthChallenge'
            ) {
              const mode = AuthType.Email
              const challengeId = result.data.authenticate.id
              const email = result.data.authenticate.maskedEmail
              const altAuthenticatorId =
                result.data.authenticate.alternateAuthenticators.find(
                  (authenticator) => authenticator.type !== mode,
                )?.id

              history.push({
                pathname: isOAuth ? '/oauth/mfa-challenge' : '/mfa-challenge',
                data: {
                  values,
                  challengeId,
                  email,
                  mode,
                  alternateAuthenticatorId: altAuthenticatorId,
                  preservedRedirect: history.location.data.preservedRedirect,
                  preservedQueryParams:
                    history.location.data.preservedQueryParams,
                  oAuthPartnerParams: history.location.data.oAuthPartnerParams,
                },
              })
            } else {
              setIsExpiredModalOpen(true)
            }
          })
          .finally(() => setIsSwitchingAuthenticator(false))
      },

    closeModal:
      ({
        setIsInvalidCodeModalOpen,
        setIsHavingTroubleModalOpen,
        setIsExceptionModalOpen,
        setException,
        setSubmitting,
      }) =>
      () => {
        setIsInvalidCodeModalOpen(false)
        setIsHavingTroubleModalOpen(false)

        setIsExceptionModalOpen(false)
        setException('')

        setSubmitting(false)
      },
    handleHelpModal:
      ({ analytics, setIsHelpModalOpen, isHelpModalOpen }) =>
      () => {
        if (isHelpModalOpen) {
          setIsHelpModalOpen(false)
        } else {
          analytics.track(BUTTON_TAPPED, HelpButtonTapped('Need Help'))
          setIsHelpModalOpen(true)
          analytics.track('Container Viewed', HelpModalViewed)
        }
      },
    handleCloseExpiredModal:
      ({ setIsExpiredModalOpen, history }) =>
      () => {
        setIsExpiredModalOpen(false)
        history.push('/')
      },
  }),
)

const CenterContainer = styled.div`
  margin: auto;
  height: 100%;
  width: 100%;
`
const LoaderContainer = styled.div`
  margin-top: -84px;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.sm}px) {
    margin-top: unset;
    margin-bottom: 4px;
  }
`

const FormContainer = styled.div`
  padding-bottom: 80px;
`

const StyledForm = styled(Form)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const VerificationInputContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
`

const SwitchModeContentContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
`

const StyledCheckboxInput = styled(CheckboxInput)`
  padding-bottom: 19px;
`

const SwitchModeButtonContent = ({ authMode }) => {
  if (authMode === AuthType.Phone) {
    return (
      <SwitchModeContentContainer>
        <SwitchModeIcon icon={AcornsIcon.Icon.MfaEmailIcon} width="16px" />{' '}
        Email my code instead
      </SwitchModeContentContainer>
    )
  } else {
    return (
      <SwitchModeContentContainer>
        <SwitchModeIcon icon={AcornsIcon.Icon.MfaPhoneIcon} width="14px" /> Text
        my code instead
      </SwitchModeContentContainer>
    )
  }
}

export const MfaChallenge = ({
  analytics,
  onBackPress,
  handleSubmit,
  handleChange,
  values,
  isSubmitting,
  isValid,
  isInvalidCodeModalOpen,
  isHelpModalOpen,
  isExpiredModalOpen,
  isHavingTroubleModalOpen,
  closeModal,
  handleHelpModal,
  handleCloseExpiredModal,
  handleResendChallengeCode,
  handleSwitchingAuthenticator,
  location,
  isSwitchingAuthenticator,
  isResendingChallenge,
  trustThisDeviceWebIsEnabled,
  env,
  isLoading,
  title,
  isOAuth,
  exception,
  isExceptionModalOpen,
}: Props) => {
  const history = useHistory()
  const [mode, setMode] = useState<AuthType>(location?.data?.mode)
  const [masked, setMasked] = useState(
    location?.data?.mode === AuthType.Phone
      ? location?.data?.phoneNumber
      : location?.data?.email,
  )

  const actionText = mode === AuthType.Phone ? 'phone number' : 'email'
  const helpText = `If you are having trouble verifying your ${actionText}, please get in touch with our support team: 855-739-2859`

  useEffect(() => {
    if (isSwitchingAuthenticator) {
      setMode(mode === AuthType.Phone ? AuthType.Email : AuthType.Phone)
    }
  }, [isSwitchingAuthenticator])

  useEffect(() => {
    setMasked(
      location?.data?.mode === AuthType.Phone
        ? location?.data?.phoneNumber
        : location?.data?.email,
    )
  }, [location?.data])

  const handleAuthenticatorSwitch = () => {
    handleSwitchingAuthenticator(
      location.data.values,
      location.data.alternateAuthenticatorId,
      history,
    )
  }

  const onAnimationEnd = () => {
    // We need to check `isLoading` here because the onAnimationEnd
    // callback fires as long as the last lottie segment is played,
    // meaning a redirect could occur unexpectedly.
    if (isLoading) {
      return
    }

    if (!isOAuth) {
      const redirect = resolveRedirect(
        env.get('defaultRedirect'),
        document.referrer,
        location.data.preservedRedirect,
      )

      analytics.track('Login Redirect', LoginRedirect(redirect))

      window.location.href = redirect
      return
    }

    if (history.location.data.chromeExtensionPartnerFlow) {
      window.location.href =
        env.get('chromeExtensionPartnerLoginOAuthRedirectUrl') +
        location.data.preservedQueryParams
      return
    }

    history.push({
      pathname: routes.oAuth.authorize,
      data: location.data,
    })
    return
  }

  const handleResend = () => {
    handleResendChallengeCode(location.data.challengeId)
  }

  if (location.data) {
    return (
      <>
        {isSubmitting ? (
          <Layout title={<Heading2>{title}</Heading2>} showBack={false}>
            <CenterContainer>
              <LoaderContainer>
                <Loader
                  loading={isLoading}
                  endSegment={[90, 160]}
                  onAnimationEnd={onAnimationEnd}
                  minimumAnimationTime={1000}
                />
              </LoaderContainer>
            </CenterContainer>
          </Layout>
        ) : (
          <Layout
            title={<Heading2>Enter code</Heading2>}
            showBack={true}
            onBackPress={onBackPress}
            rightButton={[
              {
                text: 'Help',
                actionText: 'Help',
                handler: handleHelpModal,
              },
            ]}
          >
            <HeaderSubcopy>
              We sent a code to{' '}
              <HeaderSubcopyBolded>{masked}</HeaderSubcopyBolded>. Enter it here
              to confirm your identity.
            </HeaderSubcopy>
            <ResendButton onClick={handleResend}>
              {isResendingChallenge ? (
                <Spinner height={20} width={20} />
              ) : (
                <>Resend code</>
              )}
            </ResendButton>
            <BackButtonContainer>
              <BackButtonStyled onClick={onBackPress} />
            </BackButtonContainer>
            <FormContainer>
              <StyledForm name="MFA-form">
                <VerificationInputContainer>
                  <VerificationInput
                    data-testid="verification_input"
                    autoFocus={true}
                    autocomplete="one-time-code"
                    id="ChallengeAnswer"
                    name="challengeAnswer"
                    label={'what was the code sent to you?'}
                    onChange={handleChange}
                    value={values.challengeAnswer}
                    maxLength={MAX_LENGTH}
                    type="text"
                    inputMode="numeric"
                    pattern={`[0-9]{${MAX_LENGTH}}`}
                  />
                  <TrustThisDeviceContainer>
                    {trustThisDeviceWebIsEnabled && hasUdid() && !isOAuth && (
                      <StyledCheckboxInput
                        id="TrustThisDevice"
                        name="trustThisDevice"
                        label="Trust This Device"
                        onChange={handleChange}
                        checked={values.trustThisDevice}
                        disabled={isSubmitting}
                      />
                    )}
                  </TrustThisDeviceContainer>
                </VerificationInputContainer>
                <SubmitButton
                  buttonType="submit"
                  disabled={isSubmitting || !isValid}
                  onPress={handleSubmit}
                  name="submit-button"
                >
                  Confirm
                </SubmitButton>
                <SwitchModeContainer>
                  {location.data.alternateAuthenticatorId && (
                    <>
                      {isSwitchingAuthenticator ? (
                        <Spinner height={20} width={20} />
                      ) : (
                        <SwitchModeButton
                          onClick={handleAuthenticatorSwitch}
                          disabled={isSwitchingAuthenticator}
                        >
                          <SwitchModeButtonContent authMode={mode} />
                        </SwitchModeButton>
                      )}
                    </>
                  )}
                </SwitchModeContainer>
              </StyledForm>
            </FormContainer>
          </Layout>
        )}

        <ConfirmationModal
          isOpen={isInvalidCodeModalOpen}
          actions={[
            {
              handler: closeModal,
              label: 'Try Again',
              type: ModalActionTypes.primary,
            },
          ]}
          title="Unable to verify"
          message="Looks like the code has expired or does not match"
          image={<AcornsIcon icon={AcornsIcon.Icon.AlertBlack} width="80px" />}
        />

        <ConfirmationModal
          isOpen={isHelpModalOpen}
          actions={[
            {
              handler: handleHelpModal,
              label: 'Ok',
              type: ModalActionTypes.secondary,
            },
          ]}
          title="Need help?"
          message={helpText}
          image={<AcornsIcon icon={AcornsIcon.Icon.AlertBlack} width="80px" />}
        />

        <ConfirmationModal
          isOpen={isExpiredModalOpen}
          actions={[
            {
              handler: handleCloseExpiredModal,
              label: 'Ok',
              type: ModalActionTypes.primary,
            },
          ]}
          title="Session Expired"
          message="For your security, your session has expired and you will need to log in again to continue."
          image={<AcornsIcon icon={AcornsIcon.Icon.AlertBlack} width="80px" />}
        />

        <ConfirmationModal
          isOpen={isHavingTroubleModalOpen}
          actions={[
            {
              handler: closeModal,
              label: 'Ok',
              type: ModalActionTypes.primary,
            },
          ]}
          title="Having Trouble?"
          message="It looks like you're not receiving the text messages we are sending.
      Please verify your phone number is correct, and is a number that can receive text messages"
          image={<AcornsIcon icon={AcornsIcon.Icon.AlertBlack} width="80px" />}
        />

        <AuthenticateExceptionHandler
          exception={exception}
          handleCloseExceptionModal={closeModal}
          isExceptionModalOpen={isExceptionModalOpen}
          env={env}
        />
      </>
    )
  } else {
    return <Redirect to="/" />
  }
}

export default enhance(MfaChallenge)
