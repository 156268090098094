import { Link } from 'react-router-dom'

import { Button, CheckboxInput, Title } from '@acorns/web-components'
import styled from 'styled-components'

import theme from 'theme'

export const FormContainer = styled.div`
  padding-bottom: 80px;
  max-width: 484px;
`
export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
`
export const PartnerLogo = styled.img`
  height: 64px;
`
export const Connector = styled.img`
  padding: 12px;
`
export const StyledTitle = styled(Title)`
  margin: auto;
  width: 310px;
  font-weight: 600;
  font-size: 30px;
  line-height: 41px;
  text-align: center;
  color: #191919;
  margin-bottom: 20px;
`
export const StyledDescription = styled.div`
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 40px;
`
export const StyledDisclaimer = styled.div`
  text-align: center;
  font-size: 12px;
  line-height: 16px;
  color: ${theme.colors.stone};
  margin-bottom: 35px;
`
export const StyledLink = styled(Link)`
  color: ${theme.colors.stone};
`
export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`
export const CheckboxContainer = styled.div`
  margin-bottom: 35px;
`
export const StyledCheckboxInput = styled(CheckboxInput)`
  padding-bottom: 0px;
  input + label {
    margin: 5px 0;
    font-weight: 600;
  }
`
export const ActionsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
`
export const AllowButton = styled(Button)`
  width: 60%;
  padding: 12px, 24px, 10px, 24px;
  margin-bottom: 20px;
`
export const DenyButton = styled(Link)`
  font-size: 14px;
  font-weight: 600;
  color: ${theme.colors.stone};
  line-height: 22px;
  letter-spacing: 0px;
  text-decoration: none;
  text-align: center;
`
export const CenterContainer = styled.div`
  margin: auto;
  height: 100%;
  width: 100%;
`
export const LoaderContainer = styled.div`
  margin-top: -84px;
  @media screen and (max-width: ${(props) => props.theme.breakpoints.sm}px) {
    margin-top: unset;
    margin-bottom: 4px;
  }
`
